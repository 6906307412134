<template>
  <v-card>
    <v-dynamic-form
      class="pa-4"
      v-model="data"
      v-model:valid="valid.type"
      :inputs="typeInputs"
      :readonly="!canEdit"
    />
    <v-divider />

    <v-dynamic-form
      class="pa-4"
      v-model="data"
      v-model:valid="valid.name"
      :inputs="inputs"
      :readonly="!canEdit"
    />
  </v-card>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { EventTypeSelect, FullEditor, TagSelect } from "#components";
import { Rule } from "~/services/Rules";

export default defineComponent({
  setup() {
    const { getValid, data, canEdit } = useSaveContext<any>();

    const valid = getValid();

    return {
      valid,
      data,
      canEdit,
    };
  },
  data: () => ({
    typeInputs: defineFormInputs({
      type: {
        name: "Type",
        rules: Rule.required(),
        component: EventTypeSelect,
        props: {
          hideDetails: true,
        },
      },
    }),
    inputs: defineFormInputs({
      name: {
        name: "Name",
        rules: Rule.group([Rule.required(), Rule.title()]),
        type: "text",
        props: {
          placeholder: "Afro Gala " + new Date().getFullYear(),
        },
      },
      tags: {
        name: "Tags",
        component: TagSelect,
        props: {
          types: "events",
        },
      },
      summary: {
        name: "Summary",
        rules: Rule.string.max(200),
        type: "textarea",
        class: "mb-4",
        props: {
          counter: 200,
          rows: 3,
        },
      },
      description: {
        name: "Description",
        rules: Rule.string.max(1000),
        component: FullEditor,
        props: {
          outlined: true,
          counter: 1000,
          height: 300,
        },
      },
    }),
  }),
});
</script>
