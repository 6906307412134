<template>
  <div>
    <MultiOccurrenceInput v-if="isMultiple" :creating="creating" />
    <StandardOccurrenceInput v-else />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { EventOccurrenceType } from "~/layers/admin/models";
import MultiOccurrenceInput from "./MultiOccurrenceInput.vue";
import StandardOccurrenceInput from "./StandardOccurrenceInput.vue";

export default defineComponent({
  props: {
    creating: { type: Boolean, default: false },
  },
  components: {
    MultiOccurrenceInput,
    StandardOccurrenceInput,
  },
  setup() {
    const { data } = useSaveContext<any>();

    const isMultiple = computed(() => {
      return data.value.occurrence_type !== EventOccurrenceType.STANDARD;
    });

    return {
      isMultiple,
    };
  },
});
</script>
