<template>
  <div>
    <div class="grid grid-cols-12 gap-2">
      <div class="col-span-12 md:col-span-6">
        <v-date-input
          v-model="date"
          :disabled="disabled"
          label="Date"
          density="compact"
          variant="outlined"
          rounded="lg"
          prepend-inner-icon="calendar_today"
          prepend-icon=""
          hide-details
        />
      </div>
      <div class="col-span-6 md:col-span-3">
        <TimeInput v-model="start" :disabled="disabled" label="Start time" />
      </div>
      <div class="col-span-6 md:col-span-3">
        <TimeInput
          v-model="end"
          :disabled="disabled"
          :min="start"
          label="End time"
        />
      </div>
    </div>
    <div class="text-red caption mb-3">
      <div v-for="(error, i) in errors" :key="i">{{ error }}</div>
    </div>
  </div>
</template>

<script lang="ts">
import { get } from "lodash";
import { defineComponent } from "vue";
import { VDateInput } from "vuetify/labs/VDateInput";

type DateInput = {
  date?: DateLike;
  start?: number;
  end?: number;
};

export default defineComponent({
  components: {
    VDateInput,
  },
  props: {
    modelValue: { type: Array as PropType<DateLike[]> },
    disabled: { type: Boolean, default: false },
    errorMessages: { type: [String, Array] },
  },
  setup(props, ctx) {
    const input = ref<DateInput>({});
    const { onDiscarded } = useSaveContext();

    onDiscarded(() => {
      input.value = {};
    });

    const encodeDatePeriod = (date: DateLike, start: number, end: number) => {
      const d = parseDateLike(date).startOf("day");
      return [d.set("minutes", start).toDate(), d.set("minutes", end).toDate()];
    };

    const getDate = (i: number) => {
      const d = get(props.modelValue, i);
      return d ? parseDateLike(d) : undefined;
    };

    const setInput = <K extends keyof DateInput>(
      key: K,
      value: DateInput[K]
    ) => {
      const dateInput = Object.assign({}, input.value);
      dateInput[key] = value;

      const x1 = getDate(0);
      const x2 = getDate(1);

      const date = dateInput.date || x1?.toDate();
      const start = dateInput.start || (x1 ? dateMinutes(x1) : undefined);
      const end = dateInput.end || (x2 ? dateMinutes(x2) : undefined);

      if (date && start && end) {
        const v = encodeDatePeriod(date, start, end);
        ctx.emit("update:modelValue", v);
      }

      input.value = dateInput;
    };

    const date = computed({
      set(value: DateLike | undefined) {
        setInput("date", value);
      },
      get() {
        if (input.value.date) {
          return input.value.date;
        }
        const x = get(props.modelValue, 0);
        if (x) {
          input.value.date = parseDateLike(x).toDate();
          return input.value.date as Date;
        }
      },
    });

    const start = computed({
      set(value: number | undefined) {
        setInput("start", value);
      },
      get() {
        if (input.value.start) {
          return input.value.start;
        }
        const x = get(props.modelValue, 0);
        if (x) {
          input.value.start = dateMinutes(x);
          return input.value.start;
        }
      },
    });

    const end = computed({
      set(value: number | undefined) {
        setInput("end", value);
      },
      get() {
        if (input.value.end) {
          return input.value.end;
        }
        const x = get(props.modelValue, 1);
        if (x) {
          input.value.end = dateMinutes(x);
          return input.value.end;
        }
      },
    });

    return {
      date,
      start,
      end,
    };
  },
  computed: {
    errors() {
      if (this.errorMessages) {
        return wrap(this.errorMessages);
      }
      return [];
    },
  },
});
</script>
