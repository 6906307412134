<template>
  <div class="form-section-heading">{{ title }}</div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { EventOccurrenceType } from "~/layers/admin/models";

export default defineComponent({
  setup() {
    const { data } = useSaveContext<any>();

    const title = computed(() => {
      return match(data.value.occurrence_type, {
        [EventOccurrenceType.SESSION]: "Sessions and locations",
        [EventOccurrenceType.TOUR]: "Tour locations",
        default: "Date and time",
      });
    });

    return {
      title,
    };
  },
});
</script>
