import { Rule } from "~/services/Rules";
import { defineModelSelectComponent } from "../helpers/model-select-component";
import { EventVenue } from "~/models/dobby/Event";
// import PlacesAddressInput from "./PlacesAddressInput.vue";
import AddressInput from "~/layers/ui/modules/VDynamicForm/runtime/components/AddressInput";
import { useToast } from "vue-toastification";

export default defineModelSelectComponent({
  name: "EventVenueSelect",
  icon: "place",
  placeholder: "Select venue",
  model: EventVenue,
  action: {
    title: "Add new",
    handler() {
      const { wrapConfirmInput } = useConfirm();
      const toast = useToast();

      const create = wrapConfirmInput(
        async (data) => {
          this.updating = true;
          await EventVenue.create(data)
            .then((venue) => {
              this.result = [venue, ...this.result];
              this.select(venue);
            })
            .catch(() => {
              toast.error("Failed to create Event Venue");
            })
            .finally(() => {
              this.updating = false;
            });
        },
        {
          title: "Add a new Venue",
          doneText: "Create",
          inputs: defineFormInputs({
            name: {
              name: "Name",
              rules: Rule.group([Rule.required(), Rule.title()]),
              type: "text",
              props: {
                placeholder: "e.g. Urnley Place",
              },
            },
            description: {
              name: "Description",
              rules: Rule.short(),
              type: "textarea",
              props: {
                counter: 320,
                rows: 3,
                placeholder: "Briefly describe this venue",
              },
            },
            // city: {
            //   name: 'City',
            //   rules: Rule.string.max(32),
            //   type: 'text',
            //   props: applyPropDefaults({
            //     placeholder: 'e.g. Adelaide',
            //   }),
            // },
            address: {
              name: "Address",
              rules: Rule.address(),
              // component: PlacesAddressInput,
              component: AddressInput,
              props: {
                placeholder: "Enter address",
                country: "AU",
                // label: this.form.name,
                // returnObject: true,
              },
            },
            // address: {
            //   name: 'Address',
            //   rules: Rule.title(),
            //   component: PlacesInput,
            //   props: ({
            //     placeholder: 'Enter address',
            //     country: 'AU',
            //     label: this.form.name,
            //     returnObject: true,
            //   }),
            // },
          }),
        }
      );

      return create();
    },
  },
});
