import { get, isString } from "lodash";
import { VChip, VChipGroup } from "vuetify/components";

export default defineComponent({
  name: "ChipSelect",
  props: {
    modelValue: {},
    items: { type: Array, default: () => [] },
    density: { type: String as PropType<any> },
    itemTitle: { type: String, default: "title" },
    itemValue: { type: String, default: "value" },
    readonly: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    column: { type: Boolean, default: false },
    mandatory: { type: Boolean, default: false },
  },
  render() {
    const chips = this.items.map((item) =>
      h(
        VChip,
        {
          value: isString(item) ? item : get(item, this.itemValue),
          readonly: this.readonly,
          disabled: this.disabled,
          density: this.density,
        },
        isString(item) ? item : get(item, this.itemTitle)
      )
    );

    return h(
      VChipGroup,
      {
        ...this.$attrs,
        modelValue: this.modelValue,
        density: this.density,
        disabled: this.disabled || this.readonly,
        filter: true,
        mandatory: true,
        hideDetails: true,
        column: true,
      },
      chips
    );
  },
});
