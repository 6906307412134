import { EventOccurrenceLocationType } from "~/layers/admin/models";

export const eventOccurrenceLocationTypeName = (
  type: EventOccurrenceLocationType
) => {
  return match(type, {
    [EventOccurrenceLocationType.EVENT_VENUE]: "Venue",
    [EventOccurrenceLocationType.ADDRESS]: "Address",
    [EventOccurrenceLocationType.ONLINE_LINK]: "Online",
    [EventOccurrenceLocationType.LOCATION]: "Store location",
    [EventOccurrenceLocationType.PENDING]: "To be announced",
    default: "Event location",
  });
};
