<template>
  <div>
    <OccurrenceLocationTitle />
    <EventOccurrenceInput v-model="dataModel" v-model:valid="valid" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import OccurrenceLocationTitle from "./OccurrenceLocationTitle.vue";
import EventOccurrenceInput from "./EventOccurrenceInput.vue";
import { get } from "lodash";

export default defineComponent({
  components: { OccurrenceLocationTitle, EventOccurrenceInput },
  setup() {
    const { data, useValid } = useSaveContext<any>();
    const valid = useValid("occurrences");

    const dataModel = computed({
      set(value: any) {
        if (data.value.occurrences?.length) {
          data.value.occurrences[0] = value;
        } else {
          data.value.occurrences = [value];
        }
      },
      get() {
        return get(data.value.occurrences, 0) || {};
      },
    });

    return {
      dataModel,
      valid,
    };
  },
});
</script>
