<template>
  <SectionsContent id="occurrences" :horizontal="creating" min="1">
    <template #section-content="{ model, valid, remove, canRemove }">
      <EventOccurrenceInput
        class="pt-2 px-4"
        v-model="model.value"
        v-model:valid="valid.value"
      />
      <template v-if="canRemove">
        <v-divider />
        <div class="px-4 py-2 flex justify-end">
          <v-btn @click="remove" class="text-none" variant="text">
            Remove occurrence
          </v-btn>
        </div>
      </template>
    </template>
  </SectionsContent>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import EventOccurrenceInput from "./EventOccurrenceInput.vue";
import { EventOccurrenceLocationType } from "~/layers/admin/models";

export default defineComponent({
  components: { EventOccurrenceInput },
  props: {
    creating: { type: Boolean, default: false },
  },
  setup() {
    const { data, getValid } = useSaveContext<any>();
    const valid = getValid("occurrences");

    useSectionContent<any>({
      id: "occurrences",
      sortable: true,
      addText: "Add occurrence",
      items: {
        set(occurrences) {
          data.value.occurrences = occurrences;
        },
        get() {
          return data.value.occurrences || [];
        },
        setValid(key: string, value: boolean) {
          valid.value[key] = value;
        },
        getValid(key: string) {
          return valid.value[key];
        },
        make: () => {
          return {
            location_type: EventOccurrenceLocationType.PENDING,
            location_display_on_booking_page: true,
          };
        },
      },
    });

    return {
      valid,
    };
  },
});
</script>
