<template>
  <div>
    <v-dynamic-form
      v-model="dataModel"
      v-model:valid="valid.general"
      :inputs="inputs"
      :readonly="!canEdit"
    />
    <v-divider class="my-2" />
    <div class="form-section-heading mb-1">Location</div>
    <div class="caption mb-1">
      Tell your attendees when and where to show up.
    </div>
    <v-dynamic-form
      v-model="dataModel"
      v-model:valid="valid.location"
      :inputs="locationInputs"
      :readonly="!canEdit"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {
  CheckBox,
  ChipSelect,
  EventVenueSelect,
  LocationSelect,
} from "#components";
import { Rule } from "~/services/Rules";
import { useVModel } from "@vueuse/core";
import {
  EventOccurrenceType,
  EventOccurrenceLocationType,
} from "~/layers/admin/models";
import EventDateInput from "./EventDateInput.vue";

export default defineComponent({
  props: {
    modelValue: { type: Object as PropType<any>, required: true },
    valid: { type: Boolean },
    disabled: { type: Boolean, default: false },
  },
  setup(props) {
    const { data, canEdit } = useSaveContext<any>();
    const dataModel = useVModel(props, "modelValue");
    const validModel = useVModel(props, "valid");
    const { valid, isValid } = useFormValid();

    watchEffect(() => {
      validModel.value = isValid.value;
    });

    const inputs = computed(() => {
      return defineFormInputs({
        name: {
          name: "Name",
          rules: Rule.title(),
          type: "text",
          hidden: data.value.occurrence_type === EventOccurrenceType.STANDARD,
        },
        date: {
          hideName: true,
          rules: Rule.required(),
          class: "mt-3",
          component: EventDateInput,
        },
        // starts_at: {
        //   name: "Start time",
        //   rules: Rule.group([
        //     Rule.required(),
        //     dataModel.value.ends_at
        //       ? `before:${dataModel.value.ends_at}`
        //       : undefined,
        //   ]),
        //   component: DatetimePicker,
        //   confirmEdit: true,
        //   props: {
        //     title: "Select start date",
        //     width: "auto",
        //     prependInnerIcon: "calendar_month",
        //     targetProps: {
        //       // max: data.value.ends_at,
        //       hideHeader: true,
        //     },
        //     clearable: true,
        //   },
        // },
        // ends_at: {
        //   name: "End time",
        //   rules: dataModel.value.starts_at
        //     ? `after:${dataModel.value.starts_at}`
        //     : undefined,
        //   component: DatetimePicker,
        //   confirmEdit: true,
        //   props: {
        //     title: "Select end date",
        //     width: "auto",
        //     prependInnerIcon: "calendar_month",
        //     targetProps: {
        //       // min: dataModel.value.starts_at,
        //       hideHeader: true,
        //     },
        //     clearable: true,
        //   },
        // },
      });
    });

    const locationInputs = computed(() => {
      return defineFormInputs({
        location_type: {
          hideName: true,
          component: ChipSelect,
          rules: Rule.required(),
          class: "mb-2",
          props: {
            items: [
              EventOccurrenceLocationType.ADDRESS,
              EventOccurrenceLocationType.EVENT_VENUE,
              EventOccurrenceLocationType.LOCATION,
              EventOccurrenceLocationType.ONLINE_LINK,
              EventOccurrenceLocationType.PENDING,
            ].map((type) => ({
              title: eventOccurrenceLocationTypeName(type),
              value: type,
            })),
            column: true,
          },
        },
        location_id: {
          // name: "Location",
          hideName: true,
          rules: Rule.required(),
          hidden:
            dataModel.value.location_type !==
            EventOccurrenceLocationType.LOCATION,
          component: LocationSelect,
          props: {
            label: "Select location",
            itemValue: "id",
          },
        },
        location_venue_id: {
          // name: "Venue",
          hideName: true,
          component: EventVenueSelect,
          rules: Rule.required(),
          hidden:
            dataModel.value.location_type !==
            EventOccurrenceLocationType.EVENT_VENUE,
        },
        location_link: {
          // name: "Link",
          hideName: true,
          type: "text",
          rules: Rule.group([Rule.required(), Rule.url()]),
          hidden:
            dataModel.value.location_type !==
            EventOccurrenceLocationType.ONLINE_LINK,
          props: {
            prependInnerIcon: "link",
            placeholder:
              "https://us02web.zoom.us/j/1234567890?pwd=examplepassword",
          },
        },
        location_address: {
          // name: "Address",
          hideName: true,
          rules: Rule.group([
            Rule.required(),
            Rule.string.min(5),
            Rule.string.max(64),
          ]),
          type: "text",
          hidden:
            dataModel.value.location_type !==
            EventOccurrenceLocationType.ADDRESS,
          props: {
            label: "Provide an Address or Place",
          },
        },
        location_display_on_booking_page: {
          hideName: true,
          component: CheckBox,
          class: "mb-2",
          hidden:
            dataModel.value.location_type ==
            EventOccurrenceLocationType.PENDING,
          props: {
            title: "Display on booking page",
            subtitle:
              "If disabled, attendees will not see the event location until they have purchased tickets.",
          },
        },
        reserved_seating: {
          hideName: true,
          component: CheckBox,
          class: "mb-2",
          hidden: ![
            EventOccurrenceLocationType.ADDRESS,
            EventOccurrenceLocationType.EVENT_VENUE,
            EventOccurrenceLocationType.LOCATION,
          ].includes(dataModel.value.location_type),
          props: {
            title: "Reserved seating",
            subtitle: "Indicates that this location uses reserved seating.",
          },
        },
      });
    });

    return {
      canEdit,
      dataModel,
      valid,
      inputs,
      locationInputs,
    };
  },
});
</script>
