<template>
  <div>
    <div class="flex-align gap-1">
      <span v-if="prefix">{{ prefix }}</span>
      <v-text-field
        v-model="count"
        :min="1"
        type="number"
        placeholder="1"
        class="max-w-80px"
        density="compact"
        rounded="lg"
        variant="outlined"
        :disabled="disabled"
        hide-details
      />
      <v-select
        v-model="unit"
        :items="intervals"
        type="number"
        class="max-w-120px"
        :placeholder="count > 1 ? 'Weeks' : 'Week'"
        density="compact"
        rounded="lg"
        variant="outlined"
        :disabled="disabled"
        :rules="unitRules"
        hide-details
      />
      <span v-if="suffix">{{ suffix }}</span>
    </div>
    <div class="text-red caption mb-3">
      <div v-for="(error, i) in errorMessages" :key="i">{{ error }}</div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { type IPeriodInterval, PeriodIntervalUnit } from "../models";

export default defineComponent({
  name: "PeriodIntervalInput",
  props: {
    modelValue: { type: Object as PropType<IPeriodInterval> },
    disabled: Boolean,
    prefix: String,
    suffix: String,
    errorMessages: { type: Array, default: () => [] },
  },
  computed: {
    period: {
      set(value: IPeriodInterval | undefined) {
        this.$emit("update:modelValue", value);
      },
      get(): IPeriodInterval | undefined {
        return this.modelValue;
      },
    },
    unit: {
      set(value: PeriodIntervalUnit) {
        const p: any = this.period;
        this.period = { ...p, unit: value };
      },
      get() {
        return this.period?.unit;
      },
    },
    count: {
      set(value: number) {
        const p: any = this.period;
        this.period = { ...p, count: value };
      },
      get() {
        return this.period?.count || 0;
      },
    },
    intervals() {
      return [
        { title: this.plural("Day"), value: PeriodIntervalUnit.DAY },
        { title: this.plural("Week"), value: PeriodIntervalUnit.WEEK },
        { title: this.plural("Month"), value: PeriodIntervalUnit.MONTH },
        { title: this.plural("Year"), value: PeriodIntervalUnit.YEAR },
      ];
    },
    unitRules() {
      return this.period?.count ? [(v: any) => !!v] : [];
    },
  },
  methods: {
    plural(text: string) {
      return this.count > 1 ? text + "s" : text;
    },
  },
});
</script>
