<template>
  <v-card class="pa-4">
    <div class="form-section-heading mb-1">Repeat event</div>
    <p class="caption">
      Make this a regular event.
      <v-menu open-on-hover top offset-y max-width="200">
        <template v-slot:activator="{ props }">
          <v-icon size="20" v-bind="props">help_outline</v-icon>
        </template>
        <v-alert border="top" density="compact" elevation="1" class="caption">
          An annual festival can be scheduled every year and available to sales
          channels 2 weeks before.
        </v-alert>
      </v-menu>
    </p>
    <v-dynamic-form
      class="mt-4"
      v-model="data"
      v-model:valid="valid"
      :inputs="inputs"
      :loading="loading"
    />
  </v-card>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { PeriodIntervalInput } from "#components";

export default defineComponent({
  setup() {
    const { loading, data, useValid } = useSaveContext<any>();

    const valid = useValid("schedule");

    return {
      loading,
      data,
      valid,
    };
  },
  data: () => ({
    inputs: defineFormInputs({
      schedule: {
        name: "Schedule",
        component: PeriodIntervalInput,
        class: "mb-3",
        props: {
          prefix: "Every",
        },
      },
      availability: {
        name: "Availability",
        component: PeriodIntervalInput,
        props: {
          suffix: "before",
        },
      },
    }),
  }),
});
</script>
