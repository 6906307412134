<template>
  <v-card class="pa-4">
    <div class="form-section-heading">Timing options</div>
    <v-dynamic-form
      v-model="data"
      v-model:valid="valid.time"
      :inputs="inputs"
      :readonly="!canEdit"
    />
  </v-card>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { CheckBox, TimezoneSelect } from "#components";

export default defineComponent({
  setup() {
    const { getValid, data, canEdit } = useSaveContext<any>();

    const valid = getValid();

    const inputs = computed(() =>
      defineFormInputs({
        hide_start_time: {
          hideName: true,
          component: CheckBox,
          class: "mb-2",
          props: {
            title: "Hide start time",
            subtitle:
              "The start time of your event will be hidden from attendees.",
          },
        },
        hide_end_time: {
          hideName: true,
          component: CheckBox,
          class: "mb-2",
          props: {
            title: "Hide end time",
            subtitle:
              "The end time of your event will be hidden from attendees.",
          },
        },
        timezone: {
          name: "Time Zone",
          component: TimezoneSelect,
          props: {
            label: "Select Time Zone",
          },
        },
      })
    );

    return {
      valid,
      data,
      canEdit,
      inputs,
    };
  },
});
</script>
